export default function PartnersIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46">
      <g fill="none" fillRule="evenodd">
        <rect width="46" height="46" fill="#FFF" fillOpacity=".01" rx="3" />
        <g stroke="currentColor" strokeWidth="2" transform="translate(11 10)">
          <rect width="8.036" height="8.036" x="1.418" y="3.927" rx="2" />
          <path
            fill="currentColor"
            d="M18.68 2.9a1 1 0 0 0-1.414 0l-3.72 3.72a1 1 0 0 0 0 1.414l3.72 3.719a1 1 0 0 0 1.414 0l3.719-3.719a1 1 0 0 0 0-1.414l-3.72-3.72z"
          />
          <rect width="8.036" height="8.036" x="1.418" y="16.472" rx="2" />
          <rect width="8.036" height="8.036" x="13.963" y="16.472" rx="2" />
        </g>
      </g>
    </svg>
  );
}
