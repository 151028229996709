import { ControlledDocsDomains, QMS_TYPES } from 'shared/brainElements';

import PartnersIcon from 'images/icon-components/PartnersIcon';
import AnalyticsIcon from 'images/icon-components/AnalyticsIcon';
import QmsIcon from 'images/icon-components/QmsIcon';
import ControlledDocumentsIcon from 'images/icon-components/ControlledDocumentsIcon';
import ProductionRunIcon from 'images/icon-components/ProductionRunIcon';
import UserManagementIcon from 'images/icon-components/UserManagementIcon';

// NOTE: activeRouteCheck is used for sub-routes which don't keep the "s"
// i.e. /production-runs -> /production-run/:id

export const TREE = [
  {
    name: 'Partners',
    icon: PartnersIcon,
    items: [{ name: 'Partner Specs', to: '/partners', activeRouteCheck: '/partner' }],
    isActive: (path) => ['/partner'].some((i) => path.startsWith(i)),
  },
  {
    name: 'Operations',
    icon: ProductionRunIcon,
    items: [
      {
        name: 'Production Runs',
        to: '/production-runs',
        activeRouteCheck: '/production-run',
      },
      { name: 'Colorboard QC', to: '/colorboard' },
      { name: 'Sheet QR Generator', to: '/qr-sheets' },
      ...(process.env.COLORBOARD_APP.SHOW_ASSIGN_KIT
        ? [
            {
              name: 'CPS',
              to: '/cps',
            },
          ]
        : []),
      { name: 'CB Lot Management', to: '/lots' },
      { name: 'LOCO', to: '/loco' },
    ],
    isActive: (path) =>
      [
        '/production-run',
        '/colorboard',
        '/lots',
        '/qr-sheets',
        '/loco',
        '/cps',
        '/lot-corrections',
      ].some((i) => path.startsWith(i)),
  },
  {
    name: 'QMS Processes',
    icon: QmsIcon,
    items: QMS_TYPES.map((type) => ({
      name: type.label,
      to: `/processes/${type.shortname}`,
      activeRouteRegexp: new RegExp(`/(processes|process)/${type.shortname}$`),
    })),

    isActive: (path) =>
      path.match(/\/(processes|process)\/(eco|capa|infosec-incident-reports|complaints)/),
  },
  {
    name: 'Controlled Docs',
    icon: ControlledDocumentsIcon,
    items: ControlledDocsDomains.map((name) => {
      const to = `/processes/controlled-documents/${
        name.toLowerCase() === 'all' ? '' : name
      }`;
      return {
        name,
        to,
        activeRouteRegexp: new RegExp(`^${to}$`),
      };
    }),
    isActive: (path) => path.startsWith('/processes/controlled-documents'),
  },
  {
    name: 'Analytics',
    icon: AnalyticsIcon,
    items: [{ name: 'Urine', to: '/analytics/urine' }],
    isActive: (path) => path.startsWith('/analytics'),
  },
  {
    name: 'User Management',
    icon: UserManagementIcon,
    items: ['Urine', 'WOUND', 'BRAIN', 'CPS'].map((name) => ({
      name,
      to: `/users/${name.toLowerCase()}`,
      activeRouteRegexp: new RegExp(`/(users|user)/${name.toLowerCase()}`),
    })),
    isActive: (path) => ['/user/', '/users/'].some((i) => path.startsWith(i)),
  },
];
