import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import { initGqlClient } from '@shared/utils/graphQl';
import { injectReducerAndSaga } from '@shared/redux/injectors/injectReducerAndSaga';
import { isIntegrationTest } from '@shared/utils/integrationTestUtils';

import { initLocoGqlClient } from 'containers/Loco/locoGQlClient';

import SignInPage from 'components/SignInPage';
import FullPageLoader from 'components/FullPageLoader';

import umAuthClient from './UMAuthentication';
import { testIdToken, testAccessTokenDecoded } from './testing/TestUserData';
import { updateUserData, clearUserData } from './actions';
import reducer from './reducer';

// NOTE: This component will only work if the Google client has already been loaded and initialized

const SignOutContext = createContext(() => {});
export const useSignOutFunction = () => useContext(SignOutContext);

let gqlClient;
let umClient = umAuthClient;

// We use cookies to persist auth state on page reload
const cookieOptions = {
  secure: true,
  domain: window.location.hostname,
};
const ID_TOKEN_COOKIE = 'googleIdToken';
const ACCESS_TOKEN_COOKIE = 'googleAccessToken';
const ACCESS_TOKEN_EXPIRY_COOKIE = 'googleAccessTokenExpiry';
const TOKEN_EXPIRY_TIME = 3600000 * 6; // expiration time for 6 hours

if (isIntegrationTest()) {
  /* eslint-disable-next-line global-require */
  umClient = require('./testing/UMAuthenticationMock');
  Cookies.set(ID_TOKEN_COOKIE, testIdToken, cookieOptions);
  Cookies.set(ACCESS_TOKEN_COOKIE, JSON.stringify(testAccessTokenDecoded), cookieOptions);
  Cookies.set(ACCESS_TOKEN_EXPIRY_COOKIE, Date.now() + TOKEN_EXPIRY_TIME, cookieOptions);
}

function GoogleAuthProvider({ children }) {
  // Has google id token:
  const [googleSignedIn, setGoogleSignedIn] = useState(
    Cookies.get(ID_TOKEN_COOKIE) !== undefined,
  );
  // Is signed in with UM and has google access token:
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [hasAuthError, setAuthError] = useState(false);
  const [accessTokenExpired, setAccessTokenExpired] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const dispatch = useDispatch();

  const setGqlToken = (token) => {
    if (gqlClient) {
      gqlClient.setHeaders({
        authorization: `Bearer ${token}`,
      });
    }
  };

  const updateUMToken = (response) => {
    const { umToken, umPermissions } = umClient.extractRefreshToken(response);
    dispatch(
      updateUserData({
        umToken,
        umPermissions,
      }),
    );
    setGqlToken(umToken);
  };

  const clearGoogleAuthCookies = () => {
    Cookies.remove(ID_TOKEN_COOKIE, cookieOptions);
    Cookies.remove(ACCESS_TOKEN_COOKIE, cookieOptions);
    Cookies.remove(ACCESS_TOKEN_EXPIRY_COOKIE, cookieOptions);
  };

  function handleSignOut() {
    try {
      clearGoogleAuthCookies();
      setGqlToken('');
      dispatch(clearUserData());
    } catch (e) {
      console.error(e);
    } finally {
      setIsSignedIn(false);
      setGoogleSignedIn(false);
      setAccessTokenExpired(false);
    }
  }

  const onRequestUnauthorized = (e) => {
    if (e.response?.status === 401) {
      return handleSignOut();
    }
    throw e;
  };

  const tokenResponseHandler = (tokenResponse) => {
    if (tokenResponse.access_token) {
      setTimeout(setAccessTokenExpired, TOKEN_EXPIRY_TIME, true);
      Cookies.set(ACCESS_TOKEN_COOKIE, JSON.stringify(tokenResponse), cookieOptions);
      Cookies.set(
        ACCESS_TOKEN_EXPIRY_COOKIE,
        Date.now() + TOKEN_EXPIRY_TIME,
        cookieOptions,
      );
      setAccessTokenExpired(false);
    } else {
      console.error('Failed to get access token from google');
    }
  };

  const requestAccessToken = () => {
    const client = window.google.accounts.oauth2.initTokenClient({
      client_id: process.env.GOOGLE_API_CLIENT_ID,
      scope: process.env.GOOGLE_API_SCOPES,
      prompt: '',
      callback: tokenResponseHandler,
      hint: userEmail,
    });
    // this call also sets the token for gapi.client
    client.requestAccessToken();
  };

  const checkForAccessToken = () => {
    const accessToken = Cookies.get(ACCESS_TOKEN_COOKIE);
    if (!accessToken) {
      requestAccessToken();
    } else {
      window.gapi.client.setToken(JSON.parse(accessToken));
    }
  };

  const getUserInfoFromToken = (idToken) => {
    const { email, name, picture } = jwtDecode(idToken);
    return {
      email: email ?? '',
      name: name ?? '',
      avatarUrl: picture ?? '',
    };
  };

  const handleSignIn = async (idToken) => {
    const { email, name, avatarUrl } = getUserInfoFromToken(idToken);
    setUserEmail(email);
    try {
      const { umToken, umPermissions } = await umClient.exchangeUMToken(idToken);
      gqlClient = initGqlClient(process.env.PRODUCTION_RUN.GRAPHQL_API_URL, {
        requestMiddleware: [updateUMToken],
        errorHandler: onRequestUnauthorized,
      });
      setGqlToken(umToken);
      initLocoGqlClient(idToken);
      Cookies.set(ID_TOKEN_COOKIE, idToken, cookieOptions);
      checkForAccessToken();

      dispatch(
        updateUserData({
          email,
          name,
          idToken,
          avatarUrl,
          umToken,
          umPermissions,
        }),
      );
      setIsSignedIn(true);
    } catch (e) {
      console.error(e);
      setAuthError(true);
      handleSignOut();
    }
  };

  const handleGoogleSignIn = async (res) => {
    const idToken = res.credential;
    if (idToken) {
      await handleSignIn(idToken);
    } else {
      setAuthError(true);
      handleSignOut();
    }
  };

  useEffect(() => {
    const idToken = Cookies.get(ID_TOKEN_COOKIE);
    if (idToken) {
      // user has reloaded the page and still has a valid id token
      setGoogleSignedIn(true);
      handleSignIn(idToken);
      const tokenExpiry = Cookies.get(ACCESS_TOKEN_EXPIRY_COOKIE);
      setTimeout(setAccessTokenExpired, tokenExpiry - Date.now(), true);
    }
  }, []);

  if (isSignedIn) {
    return (
      <SignOutContext.Provider value={handleSignOut}>
        {Boolean(accessTokenExpired) && (
          <FullPageLoader
            hasError
            errorText="Access to google APIs has expired"
            onErrorDismiss={() => {
              requestAccessToken();
            }}
            errorDismissButtonText="Click here to login again"
          />
        )}
        {children}
      </SignOutContext.Provider>
    );
  }
  if (!googleSignedIn) {
    return <SignInPage handleGoogleSignIn={handleGoogleSignIn} />;
  }

  return (
    <FullPageLoader
      isLoading
      hasError={hasAuthError}
      invisibleBackdrop
      loadingText="Checking authentication status"
      errorText="You are currently not authorized to access BRAIN. Please reach out to the BRAIN team to be added to the platform."
      errorDismissButtonText="Try again"
      onErrorDismiss={handleSignOut}
    />
  );
}

GoogleAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default injectReducerAndSaga({ key: 'auth', reducer })(GoogleAuthProvider);
