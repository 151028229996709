// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "div.tvSlaN11 {\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\ndiv._15nIkB76 {\n  width: 12vw;\n}\n\ndiv._15nIkB76 img.jLmTILyA {\n    width: 100%;\n    padding: 0 20px;\n    margin-bottom: 50px;\n    min-width: 190px;\n  }\n\ndiv._15nIkB76 .TS4sbVrT {\n    display: flex;\n    justify-content: center;\n    height: 50px;\n  }\n\ndiv._15nIkB76 ._1B1a4EPj {\n    color: var(--color);\n    stroke-linecap: round;\n  }\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": "tvSlaN11",
	"signInContainer": "_15nIkB76",
	"logo": "jLmTILyA",
	"googleSignInContainer": "TS4sbVrT",
	"circular-progress": "_1B1a4EPj"
};
export default ___CSS_LOADER_EXPORT___;
