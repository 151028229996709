import { stringToArrayBuffer } from '@shared/utils/converters';

export const createGDriveFolder = async ({ name, parentFolderId }) => {
  try {
    const response = await gapi.client.drive.files.create({
      resource: {
        name,
        mimeType: 'application/vnd.google-apps.folder',
        parents: [parentFolderId],
      },
      fields: 'id',
    });

    return response.result.id;
  } catch (err) {
    console.error(err.result.error.message);

    return false;
  }
};

export const getGDriveFolderByName = async ({ name, parentFolderId }) => {
  try {
    const response = await gapi.client.drive.files.list({
      q: `'${parentFolderId}' in parents and mimeType='application/vnd.google-apps.folder' and name='${name}' and trashed = false`,
    });

    return response.result.files[0]?.id;
  } catch (err) {
    console.error(err.result.error.message);
    return false;
  }
};

export const isShortcutExists = async ({ name, parentFolderId }) => {
  const response = await gapi.client.drive.files.list({
    q: `'${parentFolderId}' in parents and mimeType='application/vnd.google-apps.shortcut' and name='${name}' and trashed = false`,
  });

  return response.result.files && response.result.files.length > 0;
};

export const copyFileToGDriveFolder = async ({ fileId, toFolder }) => {
  try {
    const response = await gapi.client.drive.files.copy({
      fileId,
      parents: [toFolder],
      fields: 'id',
    });

    return response.result.id;
  } catch (err) {
    console.error(err.result.error.message);

    return false;
  }
};

export const renameGDriveFileOrFolder = async ({ fileId, name }) => {
  try {
    await gapi.client.drive.files.update({
      fileId,
      resource: { name },
    });

    return true;
  } catch (err) {
    console.error(err.result.error.message);

    return false;
  }
};

export const moveFileToGDriveOldFolder = async ({ fileId, fromFolder, toFolder }) => {
  try {
    await gapi.client.drive.files.update({
      fileId,
      addParents: toFolder,
      removeParents: fromFolder,
      fields: 'id, parents',
    });

    return true;
  } catch (err) {
    console.error(err.result?.error.message ?? err);

    return false;
  }
};

const gDriveHttpRequest = async ({ path, method, body }) => {
  const { access_token: accessToken } = gapi.client.getToken();
  const res = await fetch(`https://www.googleapis.com/${path}`, {
    method,
    headers: new Headers({
      Authorization: `Bearer ${accessToken}`,
    }),
    body,
  });
  const json = await res.json();

  if (json.error) {
    const { reason, message } = json.error.errors[0];
    throw Error(`${reason}: ${message}`);
  }

  return json;
};

export const uploadFileToGDrive = async ({ fileData, gDriveFolderId }) => {
  const file = new Blob([stringToArrayBuffer(fileData.file)], { type: fileData.type });

  const metadata = {
    name: fileData.name,
    mimeType: fileData.type,
    parents: [gDriveFolderId],
  };

  const form = new FormData();
  form.append(
    'metadata',
    new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
  );
  form.append('file', file);

  try {
    const res = await gDriveHttpRequest({
      path: 'upload/drive/v3/files?uploadType=multipart&fields=id',
      body: form,
      method: 'POST',
    });
    const fileCreated = new CustomEvent('gdrive:file_created', {
      detail: { ...res, ...metadata },
    });
    window.dispatchEvent(fileCreated);
    return res.id;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getGdriveFileBlob = async ({ gDriveId: fileId }) => {
  try {
    const { body, headers } = await gapi.client.drive.files.get({
      fileId,
      alt: 'media',
    });
    return new Blob([stringToArrayBuffer(body)], { type: headers['Content-Type'] });
  } catch (err) {
    console.error(err.result.error.message);

    return null;
  }
};

export const getGDriveFileDetails = async ({ gDriveId: fileId }) => {
  const resp = await gapi.client.drive.files.get({
    fileId,
    fields: 'id,name',
  });
  return JSON.parse(resp.body);
};

export const getGDriveFilePath = ({ gDriveId: fileId }) => {
  const getFullPath = async ({ gDriveId: id }) => {
    if (!id) return [];

    const resp = await gapi.client.drive.files.get({
      fileId: id,
      fields: 'id,name,webViewLink,parents',
    });

    const segment = JSON.parse(resp.body);

    return [
      ...(await getFullPath({ gDriveId: segment.parents && segment.parents[0] })),
      segment,
    ];
  };

  return getFullPath({ gDriveId: fileId });
};

export const createShortcut = async ({
  gDriveId: fileId,
  parentFolderId: folderId,
  shortcutName: name,
}) => {
  // check if a shortcut already exists
  const exists = await isShortcutExists({ name, parentFolderId: folderId });
  if (exists) return null;

  const shortcutMetadata = {
    name,
    mimeType: 'application/vnd.google-apps.shortcut',
    parents: [folderId],
    shortcutDetails: {
      targetId: fileId,
    },
  };

  const response = await gapi.client.drive.files.create({
    resource: shortcutMetadata,
    fields: 'id,name,parents,mimeType,shortcutDetails',
  });

  return response.result.id;
};

export const updateGdriveFileBlob = async ({ gDriveId: fileId, fileData }) => {
  try {
    const form = new FormData();
    const file = new Blob([stringToArrayBuffer(fileData.file)], { type: fileData.type });
    const metadata = {
      name: fileData.name,
      mimeType: fileData.type,
    };

    form.append(
      'metadata',
      new Blob([JSON.stringify(metadata)], { type: 'application/json' }),
    );
    form.append('file', file);

    const res = await gDriveHttpRequest({
      path: `upload/drive/v3/files/${fileId}?uploadType=multipart`,
      body: form,
      method: 'PATCH',
    });
    return res.id;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const downloadFileFromGDrive = async ({ fileId, fileName }) => {
  try {
    const { body, headers } = await gapi.client.drive.files.get({
      fileId,
      alt: 'media',
    });
    const url = URL.createObjectURL(
      new Blob([stringToArrayBuffer(body)], { type: headers['Content-Type'] }),
    );

    // https://blog.logrocket.com/programmatic-file-downloads-in-the-browser-9a5186298d5c/
    // '.' inside file names confuses the download attribute so we substitute with '_'
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName.replaceAll('.', '_') ?? 'unknown_file';
    a.target = '_blank';

    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener('click', clickHandler);
      }, 150);
    };

    a.addEventListener('click', clickHandler, false);
    a.click();

    return url;
  } catch (err) {
    console.error(err.result.error.message);

    return null;
  }
};

export const deleteGdriveShortcut = ({ gDriveId: shortcutId }) =>
  gapi.client.drive.files.delete({
    fileId: shortcutId,
  });

export const getFileListInFolder = async ({ folderId }) => {
  const resp = await gapi.client.drive.files.list({
    q: `'${folderId}' in parents`,
  });

  return JSON.parse(resp.body).files;
};
